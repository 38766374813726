import React from "react";
import { Link } from "gatsby";
import "../Assets/css/base.css";
import "../Assets/css/demo1.css";
import "../Assets/css/home.css";
import SEO from "../components/seo";
import BodyClassName from "react-body-classname";
import Header from "../components/header";
import ReactPlayer from "react-player";

class Cinema extends React.Component {
  componentDidMount() {
    const charming = require("charming");
    document.documentElement.className = "js";
    var supportsCssVars = function() {
      var e,
        t = document.createElement("style");
      return (
        (t.innerHTML = "root: { --tmp-var: bold; }"),
        document.head.appendChild(t),
        (e = !!(
          window.CSS &&
          window.CSS.supports &&
          window.CSS.supports("font-weight", "var(--tmp-var)")
        )),
        t.parentNode.removeChild(t),
        e
      );
    };
    supportsCssVars() ||
      alert(
        "Please view this demo in a modern browser that supports CSS Variables."
      );

    {
      const chars = ["$", "%", "#", "@", "&", "(", ")", "=", "*", "/"];
      const charsTotal = chars.length;
      const getRandomInt = (min, max) =>
        Math.floor(Math.random() * (max - min + 1)) + min;

      class Entry {
        constructor(el) {
          this.DOM = { el: el };
          this.DOM.image = this.DOM.el.querySelector(".content__img");
          this.DOM.title = {
            word: this.DOM.el.querySelector(".content__text")
          };
          charming(this.DOM.title.word);
          this.DOM.title.letters = Array.from(
            this.DOM.title.word.querySelectorAll("span")
          );
          this.DOM.title.letters.forEach(
            letter => (letter.dataset.initial = letter.innerHTML)
          );
          this.lettersTotal = this.DOM.title.letters.length;
          observer.observe(this.DOM.el);
        }
        enter(direction = "down") {
          this.DOM.title.word.style.opacity = 1;
          this.timeouts = [];
          this.complete = false;
          let cnt = 0;
          this.DOM.title.letters.forEach((letter, pos) => {
            const timeout = setTimeout(() => {
              letter.innerHTML = chars[getRandomInt(0, charsTotal - 1)];
              setTimeout(() => {
                letter.innerHTML = letter.dataset.initial;
                ++cnt;
                if (cnt === this.lettersTotal) {
                  this.complete = true;
                }
              }, 100);
            }, pos * 80);
            this.timeouts.push(timeout);
          });
        }

        exit(direction = "down") {
          this.DOM.title.word.style.opacity = 0;
          if (this.complete) return;
          for (let i = 0, len = this.timeouts.length; i <= len - 1; ++i) {
            clearTimeout(this.timeouts[i]);
          }
        }
      }

      let observer;
      let current = -1;
      let allentries = [];
      const sections = Array.from(
        document.querySelectorAll(".section__content")
      );
      // Preload all the images in the page..
      document.body.classList.remove("loading");
      if ("IntersectionObserver" in window) {
        document.body.classList.add("ioapi", "demo-1");

        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.intersectionRatio > 0.5) {
                const newcurrent = sections.indexOf(entry.target);
                if (newcurrent === current) return;
                const direction = newcurrent > current;
                if (current >= 0) {
                  allentries[current].exit(direction ? "down" : "up");
                }
                allentries[newcurrent].enter(direction ? "down" : "up");
                current = newcurrent;
              }
            });
          },
          { threshold: 0.5 }
        );

        sections.forEach(section => allentries.push(new Entry(section)));
      }
    }
  }

  render() {
    return (
      <BodyClassName className="demo-1 loading">
        <main>
          <SEO
            title="Cinematography · Audiovisual story telling "
            description="At Makook we’re not only great behind the camera, we’re also ridiculously good at telling stories and creating original content.
             To us every frame matters from the lighting on set to the sound design passing by the story boards. 
             we do it all  Photography, Digital sketchs, TV Commercials and Film production. Reach out to us !"
            image={
              "https://makook.space" + require("../images/cinematography.jpg")
            }
          />
          <Header />
          <div className="content">
            <div className="section__content">
              <div className="content__main">
                <span>Telling Audiovisual stories on different mediums </span>
              </div>
              <h2 className="content__text">Cinematography↓</h2>
            </div>

            <div className="section__content">
              <Link className="content__link" to="/cinematography/hirafi">
                <ReactPlayer
                  className="content__img"
                  url={require("../images/Hirafi-Algerian-artisans-in-all-thier-glory.mp4")}
                  width="100%"
                  height="100%"
                  playing
                  muted
                  loop
                />
              </Link>
              <h2 className="content__text">Hirafi</h2>
            </div>
            <div className="section__content">
              <Link
                className="content__link"
                to="/cinematography/service-technique"
              >
                <ReactPlayer
                  className="content__img"
                  url={require("../images/makook-service-technique-comedic-series.mp4")}
                  width="100%"
                  height="100%"
                  playing
                  muted
                  loop
                />
              </Link>
              <h2 className="content__text">Service Technique</h2>
            </div>

            <div className="section__content">
              <h2 className="content__text">Contact</h2>
              <div className="content__contact">
                <p className="reach-out">
                  Give us a call, or reach out to us via social media. We’re
                  always open for a chat.
                </p>
                <div className="content__info">
                  <a
                    className="contact--link--footer"
                    href="mailto:hello@makook.space"
                  >
                    hello@makook.space
                  </a>
                  <br></br>
                  <a
                    className="contact--link--footer"
                    href="tel:+213 550 246249"
                  >
                    +213 (550) 246249
                  </a>
                </div>
                <div>
                  <a
                    className="contact--link--footer"
                    href="https://www.facebook.com/makook.space"
                  >
                    Facebook
                  </a>
                  <a
                    className="contact--link--footer"
                    href="http://instagram.com/makook.space"
                  >
                    instagram
                  </a>
                  <a
                    className="contact--link--footer"
                    href="https://twitter.com/makookstudio"
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </BodyClassName>
    );
  }
}

export default Cinema;
